.title {
    text-align: center;
}

.buttonsArea {
    display: flex;
    justify-content: space-between;
    margin: 0em 0.5em 1em;
}

.datePicker {
    min-width: 150px;
    max-width: 175px;
}

.actionZone {
    min-width: 100px;
}