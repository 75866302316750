.form {
    border-radius: 15px;
    padding: 25px;
    box-shadow: inset 3px 3px 15px #28272b;
    background-color: white;
}
.form a {
    cursor: pointer;
}
.inputArea {
    display: flex;
    align-items: center;
}
.logo {
    width: 125px;
    transform: rotate(-15deg);
    margin-right: 15px;
    fill: #313338;
}
.fields {
    display: flex;
    flex-direction: column;
    margin: 5px;
}
.fields > div {
    margin: 5px;
}

.buttons {
    display: flex;
    justify-content: flex-end;
    margin: 0px 15px;
}
